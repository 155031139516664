import { Relative } from 'mds-web-ui'
import React, { Component } from 'react'
import { Search as SearchIconSVG } from 'react-feather'
import styled from 'styled-components'
import { color } from 'styled-system'

import { SearchInput } from './SearchInput'
import './docsearch.min.css'

const SearchIcon = styled(SearchIconSVG)`
  position: absolute;
  left: ${({ left = 'initial' }) => `${left}`};
  right: ${({ right = 'initial' }) => `${right}`};
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 10;
  pointer-events: none;
  ${color};
`

// style algolia
const StyledRelative = styled(Relative)`
  & span.algolia-autocomplete {
    /* ensure full width input on mobile */
    display: block !important;
  }
  .algolia-docsearch-footer {
    display: none;
  }
  /* max mobile width */
  @media only screen and (max-width: 768px) {
    & .algolia-autocomplete .ds-dropdown-menu {
      left: -8px !important;
      right: -8px !important;
      min-width: auto;
    }
    & .algolia-autocomplete .algolia-docsearch-suggestion {
      margin-bottom: 8px;
      .algolia-docsearch-suggestion--subcategory-column {
        display: block;
        float: none;
        margin-bottom: 5px;
        &:after {
          content: '';
        }
      }
    }
  }
`

// Development URL flags for local Algolia search
const MASTER_URL = 'https://moleskinestudio.com'
const LOCAL_URL = 'http://localhost:8000'

export class Search extends Component {
  componentDidMount() {
    window.docsearch({
      apiKey: 'a21ddd72d21a4cb30e3b12b9baab89b1',
      appId: 'WE3OG9WSIH',
      indexName: 'moleskinestudio',
      inputSelector: `#${this.props.inputId}`,
      // Set debug to true if you want to inspect the dropdown
      debug: false,
      algoliaOptions: {
        analyticsTags: ['mds-home']
      },
      transformData: hits => {
        return hits.map(hit => {
          // If we're in development, replace the search URL with localhost, this is just a convenience change
          // to speed up development things
          if (process.env.NODE_ENV !== 'development') {
            return hit
          }

          hit.url = hit.url.replace(MASTER_URL, LOCAL_URL)
          return hit
        })
      }
    })

    // https://github.com/algolia/docsearch/issues/418
    const docsearchInput = document.querySelector(`#${this.props.inputId}`)
    docsearchInput.setAttribute('aria-label', 'Search')
  }
  static defaultProps = {
    bg: 'white',
    inputId: 'search',
    size: 'normal',
    variant: 'normal'
  }
  render() {
    let {
      bg,
      inputId,
      size,
      variant,
      iconColor: overrideIconColor,
      ...props
    } = this.props
    const fontSize = { normal: 16, large: 20 }[size]
    const iconSize = { normal: 24, large: 24 }[size]

    const iconPadding = { normal: '42px', large: '50px' }[size]

    let iconLeft = { normal: '0px', large: '4px' }[size]
    let iconRight = { normal: '20px', large: '24px' }[size]

    const py = { normal: 12, large: 19 }[size]
    const borderRadius = { normal: 5, large: 11 }[size]

    let iconColor =
      overrideIconColor || { normal: '#707070', large: '#A7A7A7' }[size]

    const border = '2px solid transparent'

    let pl, pr
    if (variant === 'subtle') {
      bg = 'transparent'
      iconColor = overrideIconColor || 'rgba(255, 255, 255, 0.8)'
      pl = 2
      pr = iconPadding
      iconLeft = 'initial'
    } else {
      pr = 2
      pl = iconPadding
      iconRight = 'initial'
    }

    return (
      <StyledRelative width="100%" {...props}>
        <SearchInput
          id={inputId}
          width="100%"
          py={py}
          pl={pl}
          pr={pr}
          fontSize={fontSize}
          borderRadius={borderRadius}
          border={border}
          bg={bg}
          aria-label="Search"
          {...props}
        />
        <SearchIcon
          color={iconColor}
          size={iconSize}
          left={iconLeft}
          right={iconRight}
        />
      </StyledRelative>
    )
  }
}
