import { Box, Flex, Text } from 'mds-web-ui'
import { graphql, useStaticQuery } from 'gatsby'
import sortBy from 'lodash/sortBy'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { space, textAlign } from 'styled-system'

import { ActiveLink } from '../../utils/Link'
import { NavTabsHelp, SupportLink } from '../../utils/NavTabs'
import { useIsMobile } from '../../utils/useWindowSize'
import Triangle from '../Triangle'

const focusStyle = {
  border: '1px solid transparent',
  '&:focus': {
    outline: 'none',
    border: '1px solid #4b93c0',
    borderRadius: '5px'
  }
}
const buttonReset = {
  background: 'none',
  // disable double tap to zoom on mobile
  touchAction: 'manipulation',
  '&:hover': {
    opacity: 0.9
  },
  ...focusStyle
}

const SectionHeading = ({
  selected,
  expanded,
  onClick,
  children,
  expandable
}) => (
  <Flex
    as={expandable ? 'button' : 'div'}
    alignItems="center"
    onClick={onClick}
    css={{ cursor: expandable ? 'pointer' : null, ...buttonReset }}
  >
    {expandable ? <Triangle color="#858585" rotated={expanded} /> : null}
    <Text
      color="grayapptitle"
      fontSize={1}
      p={1}
      textAlign="left"
      fontWeight={selected || !expandable ? 'bold' : 'normal'}
      children={children}
      ml={expandable ? 2 : 0}
    />
  </Flex>
)

const PageLink = ({ to, title }) => {
  return (
    <div>
      <ActiveLink
        fontSize={1}
        lineHeight={1.3}
        to={to}
        color="grayapptitle"
        p={1}
        my={[1, '4px']}
        css={focusStyle}
        partiallyActive={true}
      >
        {title}
      </ActiveLink>
    </div>
  )
}

const LinkGroup = ({ expanded, expandable, ...rest }) => (
  <ExpandingBox expanded={expanded}>
    <Box pl={expandable ? 4 : 3} {...rest} />
  </ExpandingBox>
)

const NavSection = ({ selected, section, category, expandable }) => {
  const [expanded, setExpanded] = useState(selected)

  useEffect(() => {
    setExpanded(selected)
  }, [selected])

  return (
    <Box ml={[2, -1]} pl={[0, 1]} mt={-1} py={1} mb={1}>
      <SectionHeading
        selected={selected}
        expanded={expanded}
        onClick={() => {
          setExpanded(expanded => !expanded)
        }}
        expandable={expandable}
      >
        {section.title}
      </SectionHeading>
      <LinkGroup expanded={expanded || !expandable} expandable={expandable}>
        {section.items.map(item => (
          <PageLink
            key={item.id}
            to={`/support/${category.id}/${section.id}/${item.id}/`}
            title={item.title}
          />
        ))}
      </LinkGroup>
    </Box>
  )
}
const AlignableBox = styled(Box)(textAlign, space)

const getIdfromSlug = slug => {
  if (!slug) return ''
  // e.g. "/support/actions/introduction/about-actions/" --> 'about-actions'
  let parts = slug.split('/')
  return parts[parts.length - 2]
}

const getSectionPath = (category, section) =>
  `/support/${category.id}/${section.id}/`

const useGetAllSections = category => {
  const data = useStaticQuery(graphql`
    query navQuery {
      site {
        siteMetadata {
          docSections {
            actions {
              id
              title
            }
            timepage {
              id
              title
            }
            flow {
              id
              title
            }
            overlap {
              id
              title
            }
            membership {
              id
              title
            }
          }
        }
      }
      allMdx(filter: { fields: { slug: { glob: "/support/**" } } }) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            order
          }
        }
      }
    }
  `)
  const sections = data.site.siteMetadata.docSections
  const sectionPages = data.allMdx.nodes

  const subSections = sections[category.id]

  const filterByPath = ({ slug, section }) => {
    const sectionPath = getSectionPath(category, section)
    return slug.indexOf(sectionPath) === 0
  }

  const getItems = section => {
    const items = sectionPages
      .filter(({ fields: { slug } }) => filterByPath({ slug, section }))
      .map(({ fields: { slug }, frontmatter: { title, order } }) => ({
        slug: slug,
        title: title,
        order: order,
        id: getIdfromSlug(slug)
      }))
    return sortBy(items, ['order', 'title'])
  }

  return subSections.map(section => ({
    id: section.id,
    title: section.title,
    items: getItems(section)
  }))
}

const ExpandingBox = ({ expanded, children, ...rest }) => (
  <div
    style={{
      height: expanded ? '100%' : 0,
      display: expanded ? 'block' : 'none',
      overflow: 'hidden'
    }}
    {...rest}
  >
    {children}
  </div>
)

const useShouldExpand = () => {
  const [expandable, setExpandable] = useState(undefined)
  const [expanded, setExpanded] = useState(true)
  const isMobile = useIsMobile()

  useEffect(() => {
    if (isMobile != null) {
      setExpanded(!isMobile)
      setExpandable(isMobile)
    }
  }, [isMobile])

  return { expandable, expanded, setExpanded }
}

const Nav = ({ category, section, slug }) => {
  const allSections = useGetAllSections(category)
  const { expandable, expanded, setExpanded } = useShouldExpand()
  const lastSlug = useRef(null)

  useEffect(() => {
    if (lastSlug.current === null) {
      // first page
      lastSlug.current = slug
    } else if (lastSlug.current !== slug) {
      // on page change
      if (expandable) setExpanded(false)
    }
  }, [slug])

  return (
    <AlignableBox as="nav" px={3} mb={[1, 2]} textAlign="left" id="nav">
      <SupportLink />

      {expandable ||
      category.title === 'Membership' ||
      category.title === 'Overlap' ? (
        <Flex
          as={expandable ? 'button' : 'div'}
          fontSize={3}
          color="grayapptitle"
          mb={[1, 1]}
          mt={[1, 2]}
          py={[1, 2]}
          pl={2}
          ml={-2}
          alignItems="center"
          css={{
            fontWeight: 600,
            cursor: expandable ? 'pointer' : null,
            userSelect: expandable ? 'none' : null,
            ...buttonReset
          }}
          {...(expandable
            ? {
                onClick: () => {
                  setExpanded(e => !e)
                }
              }
            : {})}
        >
          {expandable ? <Triangle color="#858585" rotated={expanded} /> : null}
          <Box as="span" ml={[2, 0]} id="title">
            {category.title} Help
          </Box>
        </Flex>
      ) : (
        <NavTabsHelp app={category.title} />
      )}

      <ExpandingBox
        expanded={expandable === null ? false : expanded}
        style={{ marginLeft: '-4px' }}
      >
        {allSections.map(sec => (
          <NavSection
            key={sec.id}
            section={sec}
            category={category}
            selected={sec.id === section.id}
            expandable={
              category.expandable || (expandable && category.expandableOnMobile)
            }
          />
        ))}
      </ExpandingBox>
    </AlignableBox>
  )
}

export { Nav as default }
