import { graphql, useStaticQuery } from 'gatsby'
import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import urljoin from 'url-join'

const SchemaOrgJSON = ({
  title,
  description,
  image,
  logoURL,
  postURL,
  siteUrl,
  isArticle,
  appType,
  appPrice,
  appCategory,
  rating,
  ratingCount
}) => {
  let schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: siteUrl,
      name: title
    },
    {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: 'Bonobo',
      alternateName: ['Moleskine Studio', 'Moleskine Digital Studio'],
      sameAs: ['https://moleskinestudio.com'],
      dissolutionDate: '2024-12-31T00:00:00+08:00'
    }
  ]

  // Add SoftwareApplication schema for app pages
  if (appType) {
    let offers = undefined
    if (appPrice) {
      const [yearlyPrice, monthlyPrice] = appPrice
      offers = [
        {
          '@type': 'Offer',
          price: yearlyPrice,
          priceCurrency: 'USD',
          priceValidUntil: '2025-12-31',
          availability: 'https://schema.org/InStock',
          eligibleDuration: 'P1Y',
          description: 'Yearly subscription'
        },
        {
          '@type': 'Offer',
          price: monthlyPrice,
          priceCurrency: 'USD',
          priceValidUntil: '2025-12-31',
          availability: 'https://schema.org/InStock',
          eligibleDuration: 'P1M',
          description: 'Monthly subscription'
        }
      ]
    }

    const appSchema = {
      '@context': 'https://schema.org',
      '@type': appType === 'bundle' ? 'Product' : 'SoftwareApplication',
      name: title,
      description: description,
      image: image,
      url: postURL,
      applicationCategory: appCategory,
      operatingSystem: appType === 'bundle' ? undefined : 'iOS',
      offers,
      featureList: APP_FEATURES[appType]
    }

    // Add bundle-specific properties
    if (appType === 'bundle') {
      appSchema.category = 'Productivity Software'
      appSchema.isRelatedTo = [
        {
          '@type': 'SoftwareApplication',
          name: 'Timepage by Bonobo',
          applicationCategory: 'Calendar'
        },
        {
          '@type': 'SoftwareApplication',
          name: 'Actions by Bonobo',
          applicationCategory: 'Task Management'
        },
        {
          '@type': 'SoftwareApplication',
          name: 'Flow by Bonobo',
          applicationCategory: 'Drawing'
        },
        {
          '@type': 'SoftwareApplication',
          name: 'Overlap by Bonobo',
          applicationCategory: 'Time Management'
        }
      ]
    }

    // Only add ratings if they exist
    if (rating && ratingCount) {
      appSchema.aggregateRating = {
        '@type': 'AggregateRating',
        ratingValue: rating,
        ratingCount: ratingCount
      }
    }

    schema.push(appSchema)
  }

  if (isArticle) {
    schema.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': postURL,
              name: title,
              image
            }
          }
        ]
      },
      {
        '@context': 'http://schema.org',
        '@type': 'Article',
        url: siteUrl,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image
        },
        description: description,
        datePublished: '2024-01-01T00:00:00+08:00',
        dateModified: new Date().toISOString(),
        author: {
          '@type': 'Organization',
          name: 'Bonobo',
          logo: {
            '@type': 'ImageObject',
            url: logoURL
          }
        },
        publisher: {
          '@type': 'Organization',
          name: 'Bonobo',
          logo: {
            '@type': 'ImageObject',
            url: logoURL
          }
        },
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': postURL
        }
      }
    )
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

const APP_FEATURES = {
  timepage: [
    'Natural language input',
    'Weather integration',
    'Travel time estimation',
    'Timeline view',
    'Multiple calendar support',
    'Custom event types',
    'Cloud sync'
  ],
  actions: [
    'Smart lists',
    'Natural language input',
    'Cloud sync',
    'Collaboration features',
    'Custom categories',
    'Due date reminders'
  ],
  flow: [
    'Realistic drawing tools',
    'Natural paper textures',
    'Cloud sync',
    'Infinite canvas',
    'Custom brushes',
    'Export options'
  ],
  overlap: [
    'Visual time comparison',
    'Team scheduling',
    'Multiple timezone support',
    'Cloud sync',
    'Meeting planner',
    'Global team coordination'
  ],
  bundle: []
}

const OpenGraph = ({
  url,
  isArticle,
  title,
  description,
  image,
  imageWidth,
  imageHeight
}) => (
  <Helmet>
    <meta property="og:url" content={url} />
    <meta property="og:type" content={isArticle ? 'article' : 'website'} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    {imageWidth && <meta property="og:image:width" content={imageWidth} />}
    {imageHeight && <meta property="og:image:height" content={imageHeight} />}
  </Helmet>
)

const TwitterCard = ({ site, creator, title, description, image }) => (
  <Helmet>
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content={site ? site : ''} />
    <meta name="twitter:creator" content={creator ? creator : ''} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </Helmet>
)

export const useSiteConfig = () => {
  const config = useStaticQuery(graphql`
    query config {
      site {
        siteMetadata {
          siteUrl
          siteTitle
          siteDescription
        }
      }
      siteLogo: file(
        sourceInstanceName: { eq: "img" }
        relativePath: { eq: "mds_seo.png" }
      ) {
        childImageSharp {
          resize(width: 800, quality: 90, toFormat: PNG) {
            src
            width
            height
          }
        }
      }
    }
  `)

  return {
    ...config.site.siteMetadata,
    siteLogo: config.siteLogo.childImageSharp.resize
  }
}

const SEO = ({
  title = null,
  description = null,
  image = null,
  path = '',
  isArticle = false,
  noindex = false,
  appType = null,
  appPrice = [14.99, 2.49],
  appCategory = 'Productivity',
  rating = null,
  ratingCount = null,
  keywords = null
}) => {
  const { siteUrl, siteLogo, siteTitle, siteDescription } = useSiteConfig()

  // If not given then use site default
  if (!image) image = siteLogo
  if (!title) title = siteTitle
  if (!description) description = siteDescription

  // Absolute URLs
  const url = urljoin(siteUrl, path)
  const logoURL = urljoin(siteUrl, siteLogo.src)
  const imageURL = urljoin(siteUrl, image.src)

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={imageURL} />
        {keywords && <meta name="keywords" content={keywords} />}
        {noindex ? <meta name="robots" content="noindex" /> : null}
      </Helmet>

      {/* Schema.org tags */}
      <SchemaOrgJSON
        title={title}
        description={description}
        image={imageURL}
        logoURL={logoURL}
        postURL={url}
        siteUrl={siteUrl}
        isArticle={isArticle}
        appType={appType}
        appPrice={appPrice}
        appCategory={appCategory}
        rating={rating}
        ratingCount={ratingCount}
      />

      {/* OpenGraph tags */}
      <OpenGraph
        title={title}
        description={description}
        image={imageURL}
        imageWidth={image.width}
        imageHeight={image.height}
        url={url}
        isArticle={isArticle}
      />

      {/* Twitter Card tags */}
      <TwitterCard
        title={title}
        description={description}
        image={imageURL}
        site={siteUrl}
      />
    </Fragment>
  )
}

export default SEO
