import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Link as BaseLink, navigate } from 'gatsby'
import PropTypes from 'prop-types'

import { PrimaryButton, SecondaryButton } from '../Buttons'
import { color, layout, space, typography } from 'styled-system'
import shouldForwardProp from '@styled-system/should-forward-prop'
import { InlineLink } from './InlineLink'

const allowList = ['activeStyle', 'partiallyActive']

export const CleanGatsbyLink = styled(BaseLink)
  .withConfig({
    shouldForwardProp: (prop) => {
      if (allowList.includes(prop)) {
        return true
      }

      return shouldForwardProp(prop)
    },
  })
  .attrs((props) => {
    return {
      lineHeight: [2, 3],
      ...props,
    }
  })(
  {
    display: 'inline-block',
    textDecoration: 'none',
    transition: 'opacity ease-in-out 0.1s',
    color: 'inherit',
    '&:hover': {
      opacity: 0.8,
    },
  },
  space,
  color,
  typography,
  layout,
)

export const DefaultLink = CleanGatsbyLink

export const Link = ({
  LinkComponent = InlineLink,
  isActive,
  external = false,
  to,
  inline = false,
  newTab = false,
  ...props
}) => {
  const _LinkComponent = external ? LinkComponent : DefaultLink
  const linkProps = { ...props }

  if (external) {
    // Anchor tag by default
    linkProps.href = to
    // https://developers.google.com/web/tools/lighthouse/audits/noopener
    linkProps.rel = 'noopener'
  } else {
    // react-router or gatsby link
    linkProps.to = to
  }
  if (newTab) {
    linkProps.target = '_blank'
  }
  if (isActive) {
    linkProps.fontWeight = 'bold'
  }

  if (inline) {
    return <_LinkComponent {...linkProps} />
  } else {
    return (
      <div>
        <_LinkComponent {...linkProps} />
      </div>
    )
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  inline: PropTypes.bool,
  newTab: PropTypes.bool,
}

Link.displayName = 'Link'

///////////////////////////////////////////////////////////////////////////////
// Active Link - passes isActive down to child element (which defaults to Link)
export const ActiveLink = ({ to, exact = false, ...props }) => {
  return <Link to={to} activeStyle={{ fontWeight: 'bold' }} partiallyActive={!exact} {...props} />
}

///////////////////////////////////////////////////////////////////////////////
// for linking to local urls e.g. href='/'
export const ButtonLinkLocal = ({ type = 'secondary', href, ...rest }) => {
  // TODO check modifier keys pressed
  const onClick = useCallback(() => navigate(href), [href])

  if (type === 'secondary') {
    return <SecondaryButton {...rest} as="a" onClick={onClick} />
  } else if (type === 'primary') {
    return <PrimaryButton {...rest} as="a" onClick={onClick} />
  }

  throw Error('Invalid ButtonLinkLocal type')
}
