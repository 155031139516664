import { Text as _Text } from 'mds-web-ui'
import shouldForwardProp from '@styled-system/should-forward-prop'
import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Heading as _Heading } from 'rebass/styled-components'
import styled from 'styled-components'
import { color, flexbox, layout, space, typography } from 'styled-system'

export const fontSizes = {
  superHeading: 'min(12vh, min(15vw, 124px))',
  // huge: 'min(11vh, min(15vw, 116px))',
  // extraLarge: 'min(8.5vh, min(13vw, 86px))',
  heading: ['min(7vh, min(10.5vw, 72px))', 'min(8vh, min(12vw, 72px))'],
  // medium: 'min(5vh, min(9vw, 56px))',
  // mediumSmall: 'min(4.8vh, min(7.5vw, 42px))',
  copy: 'min(4.5vh, min(6vw, 32px))',
  // extraSmall: 'min(4vh, min(5vw, 28px))',
  // extraExtraSmall: 'min(3.8vh, min(4.8vw, 26px))',
  // tiny: 'min(3vh, min(4vw, 22px))',
  body: 'min(2.8vh, min(3.8vw, 20px))'
}

export const lineHeights = {
  h1: 1,
  h2: 1.15,
  h3: 1.15,
  h4: 1.2,
  p: 1.3
}

const headingSizes = {
  h1: fontSizes.superHeading,
  h2: fontSizes.superHeading,
  h3: fontSizes.heading,
  h4: fontSizes.copy,
  p: fontSizes.copy
}

export const Heading = forwardRef(
  ({ as = 'h1', children, color = 'var(--msk-color)', ...props }, ref) => {
    const spanRef = useRef()
    useImperativeHandle(ref, () => spanRef.current)

    return (
      <BaseHeading
        as={as}
        color={color}
        fontSize={headingSizes[as]}
        lineHeight={lineHeights[as]}
        textAlign="left"
        fontWeight="800"
        {...props}
        ref={spanRef}
      >
        {children}
      </BaseHeading>
    )
  }
)

export const Paragraph = forwardRef(
  ({ maxWidth = '40ch', children, ...props }, ref) => {
    const paragraphRef = useRef()
    useImperativeHandle(ref, () => paragraphRef.current)

    return (
      <Text
        as="p"
        color="inherit"
        fontSize={headingSizes.p}
        lineHeight={lineHeights.p}
        textAlign="left"
        maxWidth={maxWidth || '26ch'}
        fontWeight="600"
        {...props}
        ref={paragraphRef}
      >
        {children}
      </Text>
    )
  }
)

const BaseHeading = styled(_Heading).withConfig({
  shouldForwardProp
})(
  {
    zIndex: 5,
    marginBlockStart: 0,
    textDecoration: 'none',
    textWrap: 'balance'
  },
  typography,
  space,
  layout,
  color,
  flexbox
)

const Text = styled(_Text).withConfig({
  shouldForwardProp
})(
  {
    zIndex: 5,
    textWrap: 'balance'
  },
  typography,
  space,
  layout,
  flexbox,
  color
)
